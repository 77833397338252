import React, { useContext, useState } from 'react'
import { PIMCORE_URL, ENDPOINTS } from '../../gatsby-node/constants'
import AppContext from '../context/app'
import ESW_CLASSNAMES from '../esw/classnames'

import Button from 'react-bootstrap/Button'
import Spinner from 'react-bootstrap/Spinner'

import checkCircleIconWhite from '../images/svgs/check-circle-icon-white.svg'
import checkCircleIconBlack from '../images/svgs/check-circle-icon-black.svg'
import cancelicon from '../images/svgs/cancel-icon-black.svg'

export default function PromoCode() {
    const { cartId, cart, country } = useContext(AppContext)
    const { voucher_code, total_discount } = cart
    const [input, setInput] = useState(voucher_code ?? '')
    const [validating, setValidating] = useState(false)

    const handleSubmit = async (e) => {
        e.preventDefault()

        setValidating(true)
        const iso = country.node.country_iso_code
        const url = `${PIMCORE_URL}${ENDPOINTS.REDEEM_VOUCHER(iso)}`
        const options = {
            method: 'post',
            body: JSON.stringify({
                session_cart_id: cartId,
                voucher_code: input,
            })
        }

        const r = await fetch(url, options)
        const { success, message } = await r.json()
        setValidating(false)
        
        if (success) {
            if (typeof window === 'undefined') return
            const event = new Event('CART_CHANGE')
            window.dispatchEvent(event)
        } else {
            console.error(message)
        }
    }

    const handleRollback = async () => {
        setValidating(true)
        const iso = country.node.country_iso_code
        const url = `${PIMCORE_URL}${ENDPOINTS.ROLLBACK_VOUCHER(iso)}`
        const options = {
            method: 'post',
            body: JSON.stringify({
                session_cart_id: cartId,
            })
        }

        const r = await fetch(url, options)
        const { success, message } = await r.json()
        setValidating(false)

        if (success) {
            setInput('')
            if (typeof window === 'undefined') return
            const event = new Event('CART_CHANGE')
            window.dispatchEvent(event)
        } else {
            console.error(message)
        }
    }

    return (
        <div className='py-4 d-flex gap-4 flex-md-row flex-column align-items-md-center'>
            <p className='mb-0 d-inline-block fw-medium' style={{ fontSize: 14 }}>Got a promo code?</p>
            <form
                onSubmit={handleSubmit}
                className='d-inline-flex align-items-center gap-4'
            >
                <input
                    placeholder='ENTER PROMO CODE'
                    className='placeholder-font-family-primary border border-black rounded-0 px-3 w-100'
                    style={{
                        outline: 'none',
                        background: 'transparent',
                        height: 36,
                    }}
                    onChange={(e) => setInput(e.target.value.toUpperCase())}
                    value={input}
                    disabled={voucher_code ? true : false}
                    autoComplete='off'
                />
                <Button
                    type='submit'
                    variant=''
                    className={
                        `${voucher_code ? 'text-black border-black bg-transparent' :
                            input.length ? 'border-black bg-black text-white' :
                                'text-white'
                        } flex-center gap-1 border`
                    }                    
                    style={{
                        height: 36,
                        width: 120,
                        backgroundColor: '#B3B3B3',
                        borderColor: '#B3B3B3',
                    }}
                    disabled={voucher_code || !input.length ? true : false}
                >
                    {
                        validating ?
                            <Spinner variant={voucher_code ? 'dark' : 'light'} role="status" size='sm'>
                                <span className="visually-hidden">Loading...</span>
                            </Spinner>
                            :
                            <>
                                <img src={voucher_code ? checkCircleIconBlack : checkCircleIconWhite} alt='' />
                                {voucher_code ? 'APPLIED' : 'VALIDATE'}
                            </>
                    }
                </Button>
            </form>
            {voucher_code && <>
                <p
                    className={`${ESW_CLASSNAMES.DISCOUNT_ITEM} mb-0 d-inline-block fw-semibold before-negative`}
                    style={{ fontSize: 14 }}
                    data-bp-lti={`€${total_discount}`}
                >
                    {`${country.node.currency_symbol}${total_discount}`}
                </p>
                <Button variant='' className='p-0' onClick={handleRollback}>
                    <img src={cancelicon} alt='' />
                </Button>
            </>}
        </div>
    )
}