import { useStaticQuery, graphql } from 'gatsby'

export default function useGetAllProduct() {
    const data = useStaticQuery(graphql`
        query allProductQuery {
            allProduct {
                edges {
                    node {
                        id
                        productId
                        product_name
                        short_description
                        description
                        sku
                        slug
                        badge
                        cdnLinkMac
                        cdnLinkWindows
                        releasedDate
                        works_with{
                            id
                            name
                        }
                        includes {
                            productId
                        }
                        requires {
                            productId
                        }
                        internal {
                            contentDigest
                        }
                        childrenFile {
                            childImageSharp {
                                gatsbyImageData
                            }
                        }
                        miniCardFile: childrenFile {
                            childImageSharp {
                                gatsbyImageData(width: 80)
                            }
                        }
                        shop_categories {
                            id
                            name
                            slug
                        }
                    }
                }
            }
        }
    `)
    return data.allProduct.edges
}