import React, { useContext } from 'react'
import AppContext from '../context/app'

export default function Subtotal() {
    const { cart, country, prices } = useContext(AppContext)

    function reducer(total, item) {
        const { product_id, qty, } = item
        const price = prices[product_id][country.node.country_iso_code]
        total += price?.price * qty
        return total
    }

    return <>{country?.node.currency_symbol + cart?.items.reduce(reducer, 0).toFixed(2)}</>
}
