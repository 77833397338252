import { useContext, useEffect, useState } from 'react'
import AppContext from '../context/app'
import { BASE_COUNTRY_ISO } from '../../gatsby-node/constants'

export default function useGetPrice(product) {
    const { country, prices, promotions } = useContext(AppContext)
    const [price, setPrice] = useState(null)
    const [baseprice, setBaseprice] = useState(null)
    
    useEffect(() => {
        if (!product || !country || !prices || !promotions) return
        setPrice(prices[product?.productId][country?.node?.country_iso_code])
        setBaseprice(prices[product?.productId][BASE_COUNTRY_ISO])
    }, [product, country, prices, promotions])
    
    // useEffect(() => console.log(baseprice), [baseprice])
    // console.log(baseprice)
    
    return { price, baseprice }
}
