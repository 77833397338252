import { useState, useEffect } from 'react'
import { PIMCORE_URL, ENDPOINTS } from '../../gatsby-node/constants'

export default function useGetAppValue() {
    const [country, setCountry] = useState(null)
    const [cartId, setCartId] = useState('')
    const [cart, setCart] = useState(null)
    const [showCart, setShowCart] = useState(false)
    const [loadingCart, setLoadingCart] = useState(false)
    const [showNav, setShowNav] = useState(false)
    const [screenWidth, setScreenWidth] = useState(typeof window !== `undefined` ? window.screen.width : null)
    const [clientWidth, setClientWidth] = useState(typeof document !== `undefined` ? document.body.clientWidth : null)
    const [searchClicked, setSearchClicked] = useState(false)
    const [singularDeviceId, setSingularDeviceId] = useState('')
    const [prices, setPrices] = useState(null)
    const [promotions, setPromotions] = useState(null)

    useEffect(() => {
        (async () => {
            try {
                const r = await fetch(`${PIMCORE_URL}${ENDPOINTS.PRICE}`)
                const d = await r.json()
                setPrices(d?.data)
            } catch (e) {
                console.error(e)
            }
            try {
                const r = await fetch(`${PIMCORE_URL}${ENDPOINTS.PROMOTION}`)
                const d = await r.json()
                setPromotions(d?.data)
            } catch (e) {
                console.error(e)
            }
        })()
    }, [])
    
    return {
        country, setCountry,
        cartId, setCartId,
        cart, setCart,
        showCart, setShowCart,
        loadingCart, setLoadingCart,
        showNav, setShowNav,
        screenWidth, setScreenWidth,
        clientWidth, setClientWidth,
        searchClicked, setSearchClicked,
        singularDeviceId, setSingularDeviceId,
        prices, promotions,
    }    
}
