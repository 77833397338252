import React from 'react'
import Stack from 'react-bootstrap/Stack'
import useGetSiteMetadata from '../hooks/useGetSiteMetadata'
import useGetAllFile from '../hooks/useGetAllFile'

export default function Socials({ className }) {
    const { socials } = useGetSiteMetadata()
    const allFile = useGetAllFile()
    return (
        <Stack direction='horizontal' gap={4} className={className}>
            {socials.map(({ name, url, iconRelativePath }) => {
                const file = allFile.find(({ node: { relativePath } }) => relativePath === iconRelativePath)
                return (
                    <a key={file?.node?.publicURL} href={url} target='_blank' rel='noreferrer'>
                        <img src={file?.node?.publicURL} alt={name} height={24} width={24} />
                    </a>
                )
            })}
        </Stack>
    )
}
